import {
  BrowserRouter as Router, Routes, Route, Link, useLocation
} from "react-router-dom";
import { useEffect, useState } from 'react';
import { Home } from "./component/home";
import './App.css';


function App() {


  return (
    <div className="App smartNew-main">
     

      <Router>
      <Routes>
          <Route path="/" element={<Home />} />
      </Routes>
      </Router>


    </div>
  );
}

export default App;
