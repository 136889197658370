import { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate, Link, NavLink } from 'react-router-dom';
import logo from '../logo.svg';
import copy from 'copy-to-clipboard';
import Pdf from "react-to-pdf";
import {Modal} from 'react-bootstrap';
import QRCode from "react-qr-code";
import moment from 'moment-timezone';
import { navScript } from '../assets/js/funScript';
import downIco from '../assets/images/downIco.svg'
import check from '../assets/images/check.svg'
import checked from '../assets/images/checked.svg'
import successIco from '../assets/images/successIco.svg' 
import circleChecked from '../assets/images/circleChecked.svg' 
import circle from '../assets/images/circle.svg' 
import info from '../assets/images/info.png' 
import copyIco from '../assets/images/copy.png' 

import axios from 'axios';

export const Home = () => {
    const [searchParams] = useSearchParams();
    var searchParameter = searchParams.get("invoice")
    const navigate = useNavigate();
    const ref = useRef();

    const defaultTime = 15
    const [formData,setFormData] = useState({
      type:'easyload',
    })
    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [packageData, setPackageData] = useState([]);
    const [invoiceData, setInvoiceData] = useState({});
    const [allnetwork, setAllNetwork] = useState([]);
    const [correctNum, setCorrectNum] = useState(false);
    const [correctAmount, setCorrectAmount] = useState(false);
    const [showInvoice, setShowInvoice] = useState(false);

    const [selectedNetwork, setSelectedNetwork] = useState({});
    const [from, setFrom] = useState("usd");
    const [copyText, setCopyText] = useState("copy to clipboard");
    const [copyLink, setCopyLink] = useState("copy to clipboard");
  
    var [day,setDay]=useState("")
    var [hours,setHours]=useState("")
    var [minutes,setMinutes]=useState("")
    var [seconds,setSeconds]=useState("")
    var currentTime = moment.tz();
    var dateNow = new Date();

    const handleClose = (check) => {
      setShow(false)
      // check == "cancel" && timeInterval(1)
    };
    const handleShow = () => setShow(true);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShowSuccess = () => setShowSuccess(true);
  
    useEffect(()=>{
      navScript()
    },[])
  
    const handleChange = (e) => {
      setFormData({...formData,[e.target.name]:e.target.value})
      validHandle(e)
    }
    const validHandle =(e) => {
        if(formData.number?.length != 12 || formData.number.slice(0,2) != '92'  ){
        setCorrectNum(false)
      }else {
        setCorrectNum(true)
      }
         if(parseInt(formData.topupAmount) >= 100 ){
           setCorrectAmount(true)
          }else{
          setCorrectAmount(false)
      }
    }

    useEffect(()=>{
      validHandle()
    },[formData?.number,formData?.topupAmount])

    const validations = () =>{
      if(formData?.number?.length != 12  ){
        alert('invalid phone number')
        return false
      }else if(formData?.number.slice(0,2) != '92'){
        alert('number must start wit 92')
        return false
      }
      if(parseInt(formData.topupAmount) < 100){
        alert('amount must be greater than 50 pkr')
        return false
      }
      if(parseInt(formData.topupAmount) >= 5000){
        alert('amount must be less than 5000 pkr')
        return false
      }
      return true
    }

    const handleSubmit = async(e) => {
      e.preventDefault();
  
      if(validations()){
        console.log(formData);
    
        let baseRate = await usdRate()
        let cryptoRate = await tupRate()
        console.log(baseRate);
        console.log(cryptoRate);
        let sendData = {
          topupAmount:  formData.topupAmount,
          network:  formData.network,
          number:  formData.number,
          packageId: formData.type == "easyload" ? 0 : formData.packageId,
          baseRate: baseRate, //usd conversion rate
          cryptoRate:  cryptoRate, 
          startTime:  currentTime.unix() * 1000, 
          endTime:  currentTime +  (defaultTime * 60 * 1000), 
          currency:  "pkr", 
          confirmation:  "pending", 
        }
        console.log(sendData);
        await axios.post(`${process.env.REACT_APP_BASEURL}/invoice`,sendData)
        .then(function (response) {
            if(response.data.success){
                console.log(response.data.data);
                setInvoiceData(response.data.data)
                navigate(`/?invoice=${response.data.data._id}`)
          }
          if(response.data.msg){
            alert("network busy")
          }
        }).catch(function (error) {
          console.error(error);
        });
  
      
  
        
      }
    }
    var time = defaultTime
  
    const usdRate = async()=>{
      let pair = 'usd-pkr'
       return await axios.get(`${process.env.REACT_APP_BASEURL}/rate?pairName=${pair}`).then(function (response) {
          if(response.data.success){
            console.log(response.data.data[0].rates);
            return response.data.data[0].rates
          }  
        }).catch(function (error) {
          console.error(error);
        });
    }
    const tupRate = async()=>{

        return await axios.get(`${process.env.REACT_APP_BASEURL}/usdtoTup`)
       .then(function (response) {
            console.log(response.data.data[0].bids[1][0]);
            let tempTup = response.data.data[0].bids[1][0] *100000
            let actualTup = (tempTup - (tempTup * 0.04))/100000
            console.log(actualTup);
            return actualTup
          }).catch(function (error) {
            
            alert("something went wrong");
          console.error(error);
        });
    }
  
    function handletime(eventTime){    
      var timeElement, duration, interval, intervalId;
      interval = 1000; // 1 second
   
      // calculate difference between two times
    //   eventTime = currentTime +  (20 * 60 * 1000);
      duration = moment.duration(eventTime - currentTime);
      // loop to countdown every 1 second
      setInterval(function() {
        // get updated duration
        duration = moment.duration(duration - interval, 'milliseconds');
  
        // if duration is >= 0
        if (duration.asSeconds() <= 0) {
          clearInterval(intervalId);
          setDay("00")
          setHours("00")
          setMinutes("00")
          setSeconds("00")
          handleClose()
        } else {
          let day =duration.days()
          day=day.toString().replace(/\b(\d{1})\b/g, '0$1');
          let hour =duration.hours()
          hour=hour.toString().replace(/\b(\d{1})\b/g, '0$1');
          let minute =duration.minutes()
          minute=minute.toString().replace(/\b(\d{1})\b/g, '0$1');
          let seconds =duration.seconds()
          seconds=seconds.toString().replace(/\b(\d{1})\b/g, '0$1');
  
          setDay(day)
          setHours(hour)
          setMinutes(minute)
          setSeconds(seconds)
        }
       
      }, interval);
      
    }
    const getPackageFromNetwork = async() => {
      if(formData?.network){
        setFormData({ ...formData, type: "package" });
        console.log(allnetwork);
   
       await axios.get(`${process.env.REACT_APP_BASEURL}/package?company=${selectedNetwork?._id}`).then(function (response) {
          if(response.data.success){
            console.log(response.data.data);
            setPackageData(response.data.data)
          }  
        }).catch(function (error) {
          console.error(error);
        });
      }else{
        // alert("please select network")
      }
    }

    const getInvoiceData = async() => {
      setShowInvoice(true)
      await axios.get(`${process.env.REACT_APP_BASEURL}/invoice?_id=${searchParameter}`)
      .then(function (response) {
          if(response.data.data){
            console.log(response.data);
            setInvoiceData(response.data.data)
            if (response?.data?.data?.status == 3) {
              handleShowSuccess()
            } 
            handletime(response?.data?.data?.endTime)
          }else{
            setShowInvoice(false)
          }  
        }).catch(function (error) {
          setShowInvoice(false)
          console.error(error);
        });
      
    }
    const getNetworksData = async() => {
       await axios.get(`${process.env.REACT_APP_BASEURL}/allnetwork`)
       .then(function (response) {
          if(response.data.success){
            console.log(response.data.data);
            setAllNetwork(response.data.data)
           
          }  
        }).catch(function (error) {
          console.error(error);
        });
      
    }

    const handleCopy = (text) => {
      copy(text)
      setCopyText("copied")
      setTimeout(() => {
        setCopyText("copy to clipboard")
      }, 3000);
    }
    const handleCopyLink = (text) => {
      copy(text)
      setCopyLink("copied")
      setTimeout(() => {
        setCopyLink("copy to clipboard")
      }, 3000);
    }
  
    const handleDefaultPackage = async(network) => {
      let company;
        company = allnetwork.find(e => e.company.toLowerCase() == network.toLowerCase())
       
        console.log(company);
       return await axios.get(`${process.env.REACT_APP_BASEURL}/package?company=${company?._id}`).then(function (response) {
          if(response.data.success){
            console.log(response.data.data);
            setPackageData(response.data.data)
            return response.data.data
          }  
        }).catch(function (error) {
          console.error(error);
        });
    }

    const handleRegenrate =()=>{
      // navigate('/')
      // setFormData({});
      // setPackageData([]);
      // setInvoiceData({});
      // setAllNetwork([]);
      window.location.href = "/"
    }
  

    useEffect(()=>{
        console.log(searchParameter);
      if(searchParameter) {
        getInvoiceData()
      }
    },[searchParameter])
    

    useEffect(()=>{
     defaultFormFill()
    },[allnetwork])

    useEffect(()=>{
        getNetworksData()
    },[])

    const defaultFormFill = async () => {
      console.log(searchParameter);
      let allPackages;
      if(formData?.network){

      }else{
        if(searchParameter && invoiceData?.network) {
          allPackages = await handleDefaultPackage(invoiceData?.network)
      let currentPakage = allPackages.find(e=>e.id == invoiceData?.packageId)
          console.log(currentPakage);
          setFormData({
            network:invoiceData?.network,
            type:invoiceData?.packageId == 0 ? "easyload":"package",
            packageId:invoiceData?.packageId,
            number:invoiceData?.number,
            topupAmount:invoiceData?.topupAmount,
          })
        }
      }
    }

    const hanldeSelectNetWork =(item) => {
      setFormData({ ...formData, network: item?.company, type: "easyload" })
      setSelectedNetwork(item)
    }

  return (
    <>
     <header className="header">
        <nav className="container nav__con">
          <a href="/" className="logo">
            <div className="logo__img2" />
            {/* <div class="logo__title">Cryptoland</div> */}
          </a>
          <ul className="menu">
            <li className="menu__item">
              <a href="https://tenup.io/" className="menu__link" target="_blank">Home</a>
            </li>
            <li className="menu__item">
              <a href="https://tenup.io/#staking-ts" className="menu__link" target="_blank">Staking</a>
            </li>
           
            {/* <li class="menu__item">
                            <a href="#token" class="menu__link">Tokenomics</a>
                        </li> */}
            <li className="menu__item">
              <a href="https://ludonft.tenup.io/" className="menu__link" target="_blank">Ludo NFT</a>
            </li>
            <li className="menu__item">
              <a href="https://analyzer.tenup.io/" className="menu__link" target="_blank">Analyzer</a>
            </li>
            <li className="menu__item">
              <a href="https://dapp.tenup.io/wallet" className="menu__link" target="_blank">DAPP's</a>
            </li>
            <li className="menu__item">
              <a href="https://blog.tenup.io/" target="_blank" className="menu__link">Blog</a>
            </li>
            {/* <li class="menu__item">
                        <a href="#faq" class="menu__link">  </a>
                    </li> */}
          </ul>
          <div className="header__right">
            {/* <a href="" class="telegram-link">
                        <img src="img/telegram-link.png" alt="">
                    </a> */}
            <a href="https://play.google.com/store/apps/details?id=com.ludo.nft" target="_blank" className="btn-sign-in" style={{marginRight: '10px'}}>Play Store</a>
            <a href="https://dapp.tenup.io" className="btn-sign-in" target="_blank">Tenup DAPP</a>
          </div>
        </nav>
        <div className="btn-menu">
          <div className="one" />
          <div className="two" />
          <div className="three" />
        </div>
      </header>
      <div className="fixed-menu">
        <div className="fixed-menu__header">
          <Link to="/" className="logo logo--color">
            <div className="logo__img" />
            {/* <div class="logo__title">Cryptoland</div> */}
          </Link>
          <div className="btn-close">
            
          </div>
        </div>
        <div className="fixed-menu__content">
          <ul className="mob-menu">
            <li className="mob-menu__item">
              <a href="#first-screen" className="mob-menu__link">Home</a>
            </li>
            <li className="mob-menu__item">
              <a href="#staking" className="mob-menu__link">Staking</a>
            </li>
            <li className="mob-menu__item">
              <a href="https://bridge.tenup.io/" target="_blank" className="mob-menu__link">Bridge</a>
            </li>
            <li className="mob-menu__item">
              <a href="#token" className="mob-menu__link">Tokenomics</a>
            </li>
            <li className="mob-menu__item">
              <a href="#map" className="mob-menu__link">Roadmap</a>
            </li>
            <li className="mob-menu__item">
              <a href="#docs" className="mob-menu__link">WhitePaper</a>
            </li>
            <li className="mob-menu__item">
              <a href="#projs" className="mob-menu__link">DAPP's</a>
            </li>
          </ul>
          <div className="btn-wrap">
            <a href="https://dapp.tenup.io" className="btn-sign-in">Tenup DAPP</a>
          </div>
        </div>
      </div>
        
      <div className="topupSection">
        <div className="container">
          {searchParameter ?
          <>
          {showInvoice && 
          <h1>Scan to pay</h1>
          }
          </>
          :
          <>
            <h1>TENUP TOP UP</h1>
            <div className="desc">EasyLoad your sim card with Tenup quickly and conveniently in a few simple steps!</div>
          </>
          }
          
          {searchParameter ?
          <>
          {showInvoice ?
          <div className="row jc-sb ai-center jc-center">
            <div className="col-lg-6 formCol">
              <div className="qrModal">
                {invoiceData?.endTime > currentTime && invoiceData?.status == 0 &&
                <h3 className='timer mb-3'><span>{minutes}</span>:<span>{seconds}</span></h3>                
                }
                <div className="qrCard bg-white">
                  {/* qrcode */}
                  <div className={invoiceData?.endTime > currentTime && (invoiceData?.status == 0 || invoiceData?.status == 4)?"":"blurFg"} style={{ height: "auto", margin: "0 auto", maxWidth: 290, width: "100%" }}>
                      <QRCode
                      size={290}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={invoiceData?.addressId?.address || 0}
                      />
                  </div>
                </div>
                <div className="addCard">
                  <div className="address">{invoiceData?.addressId?.address && invoiceData?.addressId?.address}
                      <div className="imgDiv">
                        <img onClick={()=>handleCopy(invoiceData?.addressId?.address || 0)} src={copyIco} alt="copy" />
                        <div className="copyText">{copyText}</div> 
                      </div>
                    </div>
                </div>
                {invoiceData?.status == 3 &&
                  <div className='executedMain'>
                    <img src={successIco} alt="" />
                    <span>Executed</span>
                  </div>
                }
                {invoiceData?.endTime > currentTime && invoiceData?.status == 0?
                <p className='text mb-1'>Scan QR code with your mobile device.</p>
                : invoiceData?.status == 2 ?
                <p className='text mb-1'>You payment is recieved Please wait for settling in. </p>
                :
                ""
                }
                
              
                {/* <button className='btn-sign-in m-auto' style={{lineHeight:'45px'}} onClick={()=>handleClose("cancel")}>Cancel</button> */}
              </div>
            </div>
            <div className="col-lg-6">
          
              <div className="receptMain" >
                <div className="rCard">
                  <div className="priceMain">
                      <div className="title">Estimated Price: </div>
                      <div className="tup">{((parseFloat(invoiceData?.topupAmount)/parseFloat(invoiceData?.baseRate))/parseFloat(invoiceData?.cryptoRate)).toFixed(2)} TUP</div>
                  </div>
                </div>
                <div className="rCard" ref={ref}>
                  <div className="detailMain">
                      <div className="heading">Your details</div>
                      <div className="dRow">
                        <div className="key">Invoice ID</div>
                        <div className="val">{invoiceData?._id}</div>
                      </div>
                      <div className="dRow">
                        <div className="key">Network</div>
                        <div className="val">{invoiceData?.network}</div>
                      </div>
                      <div className="dRow">
                        <div className="key">Phone Number</div>
                        <div className="val">{invoiceData?.number}</div>
                      </div>
                      <div className="dRow">
                        <div className="key">Price</div>
                        <div className="val">RS {invoiceData?.topupAmount}</div>
                      </div>
                      <div className="dRow">
                        <div className="key">Load Type</div>
                        <div className="val">{invoiceData?.packageId == 0 ?"Easyload":"Package"}</div>
                      </div>
                      <div className="dRow">
                        <div className="key">Invoice Link</div>
                        <div className="val">
                          <div className="address">Copy Link
                              <div className="imgDiv">
                                <img onClick={()=>handleCopyLink(window.location.origin +"/?invoice="+ invoiceData?._id )} src={copyIco} alt="copy" />
                                <div className="copyText">{copyText}</div> 
                              </div>
                          </div>
                        </div>
                      </div>
                      <div className="dRow">
                        <div className="key">Status</div>
                        <botton className={`btn statusLabel ${invoiceData?.confirmation}`}>{invoiceData?.confirmation}</botton>
                      </div>
                      <div className="dRow">
                        <div className="key">Date</div>
                        <div className="dateNow">{dateNow.toLocaleString()}</div>
                      </div>

                      {invoiceData?.packageId != 0 &&
                      <>
                      <div className="dRow border-none">
                        <div className="val">Package Details:</div>
                      </div>
                      <div className="detail">
                      {(packageData?.find(e=>e.id == formData?.packageId))?.description}
                      </div>
                      </>
                      }
                  </div>
                </div>
                <div className="sendBtn">
                  <Pdf targetRef={ref} filename="Invoice.pdf">
                    {({ toPdf }) => <div type='text' onClick={toPdf} className='btn-sign-in button1  me-3' >Save Receipt</div>}
                  </Pdf>
                  
                  <button className='btn-sign-in button ' onClick={()=>handleRegenrate()}>Regenerate Order</button>
                </div>
                
              </div>
            </div>
          </div>
          :
          <h1>Invalid Invoice</h1>
          }
          </>
          :
          <div className="row jc-sb ai-center jc-center">          
            <div className={show ?"col-lg-6 " : "col "} >
             <form className='topupForm' onSubmit={(e)=>handleSubmit(e)}>
                <div className="d-flex ai-center">
                <div className="helpIco me-3">
                  <img className='' src={info} alt="" />
                  <div className="text">Select network </div>
                </div>

                  <div className="selectMain w-100">
                    
                    {/* =============== */}
                    <div className="dropdown no-arrow nav-item ">
                      <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <div className="input form-control w-100 ml-2 text-uppercase networkDrop">
                               {formData?.network &&
                                <div className="Icoimg">
                                  <img className='' src={window.location.origin + selectedNetwork?.imgUrl} alt="" />
                                </div>
                               } 
                              
                              {formData?.network ?formData?.network:"Please select Network"}
                          </div>
                        
                      </a>
                      {/* Dropdown - User Information */}
                      <div className="dropdown-menu dropdown-menu-right shadow " aria-labelledby="userDropdown">
                      {allnetwork?.map((item,key)=>(
                        <a key={key} className="dropdown-item text-uppercase" href="#" 
                           onClick={(e) => hanldeSelectNetWork(item)}>
                            <div className="Icoimg">
                              <img className='' src={window.location.origin + item?.imgUrl} alt="" />
                            </div>
                            {item?.company}
                        </a>
                      ))
                      }
                     
                      </div>
                    </div>
                    {/* =============== */}


                    <img className='ico' src={downIco} alt="" />
                  </div>
                  {formData?.network?
                    <img className='check ms-4' src={checked} alt="" />
                    :
                    <img className='check ms-4' src={check} alt="" />
                    }
                </div>
                <div className='d-flex ai-center'>
                  <div className="helpIco me-3">
                    <img className='' src={info} alt="" />
                    <div className="text">Enter you phone number and recheck it. </div>
                  </div>
                  <input className="input form-control" name="number" value={formData?.number}  onChange={(e)=>handleChange(e)}  type="number" placeholder='Enter Your Phone Number. e.g. 921234567890'  required />
                  {correctNum?
                    <img className='check ms-4' src={checked} alt="" />
                    :
                    <img className='check ms-4' src={check} alt="" />
                  }    
                </div>
                <div className='d-flex ai-center'>
                  <div className="helpIco me-3">
                    <img className='' src={info} alt="" />
                    <div className="text">Select option you want to use.</div>
                  </div>
                  <div className="easyLoadBtn ">
                    <div className={formData?.type == "easyload"?" button active" : " button"}
                      onClick={(e) => {setFormData({ ...formData, type: "easyload" })}} >
                      <img src={formData?.type == "easyload"?circleChecked:circle} alt="" /> Easyload
                    </div>
                    <div className={formData?.type == "package"?" button active" : " button"}  
                      onClick={(e) => {getPackageFromNetwork()}} >
                      <img src={formData?.type == "package"?circleChecked:circle} alt="" /> Package
                    </div>
                  </div>
                </div>
                {/* <div className="d-flex ai-center">
                  <div className="helpIco me-3">
                    <img className='' src={info} alt="" />
                    <div className="text">Select a package.</div>
                  </div>
                
                  {formData?.network?
                    <img className='check ms-4' src={checked} alt="" />
                    :
                    <img className='check ms-4' src={check} alt="" />
                    }
                </div> */}

              
                <div className='d-flex ai-center'>
                  <div className="helpIco me-3">
                    <img className='' src={info} alt="" />
                    <div className="text">Enter amount  </div>
                  </div>

                  <div className='amountPackage'>
                    <input className="input form-control " disabled={formData?.type == "package" ? true :false} name="topupAmount" value={formData?.topupAmount}  onChange={(e)=>handleChange(e)} type="number" placeholder='Enter Amount in PKR' required  />
                      <div className="selectMain w-100">
                      
                      {/* =============== */}
                      <div className="dropdown no-arrow nav-item ">
                        <a className=" dropSelect dropdown-toggle" href="#" id="userDropdown" role="button" data-bs-toggle={formData?.type == "package" ?"dropdown":""}  aria-haspopup="true" aria-expanded="false">
                            <div className={formData?.type == "easyload"?
                            "input disabled form-control w-100 ml-2 text-uppercase networkDrop"
                            :
                            "input form-control w-100 ml-2 text-uppercase networkDrop"
                            }
                            >
                              <>
                              {formData?.packageId ?
                              <div>
                                <div className="dropdownamount">Amount: <span>{(packageData?.find(e=>e.id == formData?.packageId)).amount}</span></div>
                                <div className="dropdowndetail">details: <span>{(packageData?.find(e=>e.id == formData?.packageId)).description}</span></div>
                              </div>
                              :
                              <>
                                {formData?.network ?formData?.network:"Please select Package"}
                              </>
                              }
                              </>
                            </div>
                          
                        </a>
                        {/* Dropdown - User Information */}
                        <div className="dropdown-menu dropdown-menu-right shadow" aria-labelledby="userDropdown">
                          <div className='downMM'>
                              {packageData?.map((item,key)=>(
                              <a key={key} className="dropdown-item text-uppercase" href="#" 
                                  onClick={(e) => setFormData({ ...formData, packageId: item?.id, topupAmount: item?.amount})}>
                                  <div>
                                  <div className="dropdownamount">Amount: <span>{item?.amount}</span></div>
                                  <div className="dropdowndetail">details: <span>{item?.description}</span></div>
                                  </div>
                              </a>
                              ))}
                          </div>
                  
                        </div>
                      </div>
                      {/* =============== */}


                      <img className='ico' src={downIco} alt="" />
                    </div>

                  </div>
                  {correctAmount?
                    <img className='check ms-4' src={checked} alt="" />
                    :
                    <img className='check ms-4' src={check} alt="" />
                    }
                </div>  
                {searchParameter &&
                <div className="estimate">
                  <p>Estimated Price: <span>{((parseFloat(invoiceData?.topupAmount)/parseFloat(invoiceData?.baseRate))/parseFloat(invoiceData?.cryptoRate)).toFixed(2)} TUP</span></p>
                </div>
                }
                <div className="sendBtn">                  
                  <button type='submit' className='btn-sign-in mx-auto mt-3' >Topup Now</button>
                </div>
              </form>
            </div>
          </div>
          }
          
        </div>
      </div>

      <Modal show={showSuccess} className="qrModalMain" onHide={handleCloseSuccess}  centered>
          <div className="qrModal succesModal">
                <div className="successIco">
                  <img src={successIco} alt="successIco" />
                </div>
                <h3 className='price'>Success!</h3>
                <p className='text'>Thanks for Using, Tenup Topup Service.</p>
                <button className='btn-sign-in m-auto' style={{lineHeight:'45px'}} onClick={handleCloseSuccess}>Continue</button>
          </div>
      </Modal>
    </>
  )
}
