import $ from 'jquery'
export function navScript() {
    "use strict";
    
    
        
        // menu toggle btn 
        var menu = document.getElementsByClassName('btn-menu')[0];
        menu.addEventListener('click',(()=>{
            console.log("click");
            $('.fixed-menu').addClass('open');
        }))
    
        $('.btn-close').click(function(){
            $('.fixed-menu').removeClass('open');
        });
    
        $(window).resize(function(){
            var ww = $(window).width();
            if(ww > 1200) {
                $('.fixed-menu').removeClass('open');
            }
        });
    
        $('.menu__link').on('click', function(event) {
                   
            var target = $(this.getAttribute('href'));
            if( target.length ) {
                event.preventDefault();
    
                $('html, body').stop().animate({
                    scrollTop: target.offset().top - 90
                }, 1000);
    
    
            } 
            
        });
        
        $('.mob-menu__link').on('click', function(event) {
            var target = $(this.getAttribute('href'));
            if( target.length ) {
                event.preventDefault();
                $('html, body').stop().animate({
                    scrollTop: target.offset().top - 90
                }, 1000);
                $('.fixed-menu').removeClass('open');
            }
        });
}


    